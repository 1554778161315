.PrivacyPolicy {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  font-family: Roboto, Arial, sans-serif;
}
.PrivacyPolicy-Wrapper {
  position: relative;
  width: calc(100% - 30px);
  max-width: 555px;
  background: #FFF;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.15);
  padding: 10px 40px 7px 12px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  box-sizing: border-box;
}
.PrivacyPolicy-Wrapper_hidden {
  opacity: 0;
}
.PrivacyPolicy-Wrapper_adoption {
  padding: 10px 12px 7px;
  max-width: 732px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media only screen and (min-width: 732px) {
  .PrivacyPolicy-Wrapper_adoption {
    flex-direction: row;
  }
}
.PrivacyPolicy-Wrapper_staticLayout {
  flex-direction: column !important;
}
.PrivacyPolicy-P {
  color: #888;
  font-size: 10px;
  margin: 0;
}
.PrivacyPolicy-P a, .PrivacyPolicy-P a:link, .PrivacyPolicy-P a:visited, .PrivacyPolicy-P a:hover, .PrivacyPolicy-P a:active {
  color: #348CDD;
  text-decoration: underline;
  display: inline;
}
.PrivacyPolicy-P_adoption {
  color: #888;
}
.PrivacyPolicy-P_adoption a, .PrivacyPolicy-P_adoption a:link, .PrivacyPolicy-P_adoption a:visited, .PrivacyPolicy-P_adoption a:hover, .PrivacyPolicy-P_adoption a:active {
  color: #348CDD;
}
.PrivacyPolicy-CloseIcon {
  height: 12px;
  width: 12px;
}
.PrivacyPolicy-Button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  border: 0;
  background: transparent;
  cursor: pointer;
  z-index: 1;
}
.PrivacyPolicy-ButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  flex: 0 0;
  margin-top: 10px;
}
@media only screen and (min-width: 732px) {
  .PrivacyPolicy-ButtonWrapper {
    width: unset;
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    padding-left: 18px;
  }
}
.PrivacyPolicy-ButtonWrapper_staticLayout {
  width: 100% !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: 10px !important;
  padding-left: unset !important;
}
.PrivacyPolicy-ButtonAd {
  flex: 1 0;
  border-radius: 24px;
  background: #fff;
  border: 1px solid #888;
  max-width: 160px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #888;
  cursor: pointer;
}
@media only screen and (min-width: 732px) {
  .PrivacyPolicy-ButtonAd {
    width: 160px;
  }
}
.PrivacyPolicy-ButtonAd_accept {
  background: #888;
  color: #fff;
}
.PrivacyPolicy-ButtonAd_staticLayout {
  width: unset !important;
}
